import React from 'react'
import { Icon } from 'semantic-ui-react'

const Contact = props => {
    return(
        <div className="contact-container">
            <h3> I can be reached by <a href= "mailto:burakkocak884@gmail.com" target="_blank" rel="noopener noreferrer">email at burakkocak884@gmail.com</a> and phone at using call/text 1(678)4904600,</h3>           
            <h3>Open my <a href="../files/BurakKocak.pdf" target="_blank" download className="download">Resume</a> in PDF format,</h3>
            <h3>Source code in some of my projects is also available for public at <a href = "https://github.com/burakkocak884" target="_blank" rel="noopener noreferrer"> GitHub</a>,</h3>
            <h3>You can also reach me using social media links below:</h3>
            <h3><span className="socia
            l-logo"><a href= "https://www.linkedin.com/in/burak-kocak-3bb572141/" target="_blank" rel="noopener noreferrer"><Icon className="linkedin"></Icon></a></span></h3>
        </div>
    )

    }

export default Contact